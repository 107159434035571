import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductService } from './product.services';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers:[
        ProductService
    ]

})
export class ServiceModule { }
