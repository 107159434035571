import { IClientInformation } from "../../models/IClient"


export class ChangeView {
    static readonly type = '[CLIENT] change view'
    constructor(public payload: string) {}
}
export class InformationClient {
    static readonly type = '[CLIENT] information client'
    constructor(public payload: IClientInformation) {}
}