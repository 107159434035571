import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "environments/environment";
import { User, Role } from "app/auth/models";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { UserService } from "./user.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  protected api: string;
  public currentUser: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private router: Router,
    private _userService: UserService
  ) {
    this.api = environment.apiUrl;
    const currentUser = localStorage.getItem("currentUser");

    if (currentUser) {
      this.currentUserSubject = new BehaviorSubject<any>(currentUser);
      this.currentUser = this.currentUserSubject.asObservable();
    }
  }

  // getter: currentUserValue
  // public get currentUserValue(): User {
  //   return this.currentUserSubject.value;
  // }

  // /**
  //  *  Confirms if user is admin
  //  */
  // get isAdmin() {
  //   return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  // }

  // /**
  //  *  Confirms if user is client
  //  */
  // get isClient() {
  //   return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  // }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  public login(request): Observable<any> {
    const uri = `${this.api}/login`;
    return this._http.post(uri, request).pipe(
      map(
        (response) => response,
        (error: Error) => {
          console.error(error);
        }
      )
    );
  }

  public getToken(): string {
    return localStorage.getItem("token");
  }

  public deleteToken(): void {
    localStorage.removeItem("token");
    localStorage.clear();
  }

  public async isAuthenticated(): Promise<boolean> {
    const token: string = localStorage.getItem("token");
    const idUser: number = Number(localStorage.getItem("user_id"));

    if (token) {
      const response = await this.fetchIsAuthenticated(idUser).toPromise();
      switch (response.status.code) {
        case 200:
          return true;
        default:
          this.deleteToken();
          this.router.navigate([`pages/authentication/login-v1`]);
          return false;
      }
    }
    this.deleteToken();
    this.router.navigate([`pages/authentication/login-v1`]);
    return false;
  }

  public fetchIsAuthenticated(idUser: number): Observable<any> {
    const uri = `${this.api}/isAuthenticated/${idUser}`;
    return this._http.get(uri).pipe(
      map(
        (response: any) => response,
        (error: Error) => {
          console.error(error);
        }
      )
    );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
    localStorage.clear();
    // notify
    this.currentUserSubject.next(null);
  }
}
