export const locale = {
  lang: "en",
  data: {
    MENU: {
      DASHBOARD: {
        SECTION: 'Dashboard',
      },
      PRODUCT: {
        SECTION: "Productos",
        // MOVEMENT: {
        //   COLLAPSIBLE: "Movimiento"
        // },
        // STOCK:"Stock"
      },
      CLIENT: {
        SECTION:"Cliente",
      },
      SALES: {
        SECTION:"Sales",
      }
    }
  }
}