import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ICategory, IQuality } from '../models/IProduct';
import { IResponse } from '../models/IResponse';

@Injectable({providedIn: 'root'})
export class ProductService {
    protected api:string;

    constructor(private _httpClient: HttpClient) { 
        this.api = environment.apiUrl
    }

    public getQualities(): Observable<IResponse> {
        const uri =`${this.api}/getCalidades`
        return this._httpClient.get(uri).pipe(
            map(
                (response:any) => {
                    return response;
                },
                (error:Error) => { 
                    console.error(error)
                }
            ),
            catchError((error) => throwError(error))
        )
    }

    public getCategories(idQuality: any): Observable<IResponse>{
        const uri = `${this.api}/getCategorias/${idQuality}`
        return this._httpClient.get(uri).pipe(
            map(
                (response:any) => {
                    return response;
                },
                (error:Error) => { 
                    console.error(error)
                }
            ),
            catchError((error) => throwError(error))
        )
    }

    public getSubCategories(idCategory:any) : Observable<IResponse>{
        const uri =  `${this.api}/getSubCategorias/${idCategory}`
        return this._httpClient.get(uri).pipe(
            map(
                (response:any) => {
                    return response;
                },
                (error:Error) => { 
                    console.error(error)
                }
            ),
            catchError((error) => throwError(error))
        )
    }

    public getProductFilter(idSubCategory): Observable<IResponse> {
        const uri =  `${this.api}/getProductosSC/${idSubCategory}`
        return this._httpClient.get(uri).pipe(
            map(
                (response:any) => {
                    return response;
                },
                (error:Error) => { 
                    console.error(error)
                }
            ),
            catchError((error) => throwError(error))
        ) 
    }

    public getMovementProduct(idProducto):Observable<IResponse> {
        const uri =  `${this.api}/movimientoProducto/${idProducto}`
        return this._httpClient.get(uri).pipe(
            map(
                (response:any) => {
                    return response;
                },
                (error:Error) => { 
                    console.error(error)
                }
            ),
            catchError((error) => throwError(error))
        ) 
    }
    
}