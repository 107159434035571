import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "environments/environment";
import { User } from "app/auth/models";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { IResponse } from "app/main/models/IResponse";

@Injectable({ providedIn: "root" })
export class UserService {
  protected api: string;
  public user: any;
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {
    this.api = environment.apiUrl;
  }

  /**
   * Get all users
   */
  // getAll() {
  //   return this._http.get<User[]>(`${environment.apiUrl}/users`);
  // }

  // /**
  //  * Get user by id
  //  */
  // getById(id: number) {
  //   return this._http.get<User>(`${environment.apiUrl}/users/${id}`);
  // }

  public getUser(): Observable<any> {
    const uri = `${this.api}/user`;
    return this._http.get(uri).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error: Error) => {
          console.error(error);
        }
      ),
      catchError((error) => throwError(error))
    );
  }
}
