import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { IClientInformation } from "../../models/IClient";
import { ITotalSalesClient } from "../../models/ISales";
import { ChangeView, InformationClient } from "./client.actions";

export interface IClientStateModel {
  changeView: string;
  informationClient: IClientInformation;
}

@State<IClientStateModel>({
  name: "Client",
  defaults: {
    changeView: "",
    informationClient: null,
  },
})
@Injectable()
export class ClientState {
  @Selector()
  public static changeView(state: IClientStateModel): string {
    return state.changeView;
  }

  @Selector()
  public static getInformationClient(
    state: IClientStateModel
  ): IClientInformation {
    return state.informationClient;
  }

  @Action(ChangeView)
  changeView(
    { getState, setState }: StateContext<IClientStateModel>,
    { payload }: ChangeView
  ): void {
    const state = getState();
    setState({
      ...state,
      changeView: payload,
    });
  }

  @Action(InformationClient)
  informationClient(
    { getState, setState }: StateContext<IClientStateModel>,
    { payload }: InformationClient
  ): void {
    const state = getState();
    setState({
      ...state,
      informationClient: payload,
    });
  }
}
