import { ICategory, IQuality, ISubCategory } from "app/main/models/IProduct";

export class GetQuality {
    static readonly type = '[PRODUCT] select Quality'
    constructor(public payload: IQuality) {}
}

export class GetCategory {
    static readonly type = '[PRODUCT] select Category'
    constructor(public payload: ICategory) {}
}

export class GetSubCategory {
    static readonly type = '[PRODUCT] select SubCategory'
    constructor(public payload: ISubCategory) {}
}

export class GetProductFiltered {
    static readonly type = '[PRODUCT] display product filtered'
    constructor(public payload: any) {}
}

export class ShowMessage {
    static readonly type = '[PRODUCT] show message no data'
    constructor(public payload: any) {}
}


// export class ClickQuality {
//     static readonly type = '[PRODUCT] set category'
//     constructor(public payload: any) {}
// }