import { CoreMenu } from "@core/types";

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  {
    id: "menu",
    title: "",
    type: "section",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        icon: "pie-chart",
        translate: "MENU.DASHBOARD.SECTION",
        url: "pages/dashboard",
      },
      {
        id: "products",
        title: "Productos",
        type: "item",
        icon: "award",
        translate: "MENU.PRODUCT.SECTION",
        url: 'pages/product/stock-product'
        // children: [
        //   {
        //     id: "movement",
        //     title: "Movimiento",
        //     translate: "MENU.PRODUCT.MOVEMENT.COLLAPSIBLE",
        //     type: "item",
        //     icon: "circle",
        //   },
        //   {
        //     id: "stock",
        //     title: "Stock",
        //     translate: "MENU.PRODUCT.STOCK",
        //     type: "item",
        //     icon: "circle",
        //     url: "pages/product/stock-product",
        //   },
        // ],
      },
      {
        id: "client",
        title: "Clientes",
        type: "item",
        icon: "users",
        translate: "MENU.CLIENT.SECTION",
        url: 'pages/client/list-client'
      },
      {
        id: "sales",
        title: "Ventas",
        type: "item",
        icon: "tag",
        translate: "MENU.SALES.SECTION",
        url: 'pages/sales'
      }

    ],
  },
];
