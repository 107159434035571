import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ICategory, IQuality, ISubCategory } from "app/main/models/IProduct";
import { GetCategory, GetProductFiltered, GetQuality, GetSubCategory, ShowMessage } from "./product.actions";
export interface IProductModel {
    quality: IQuality,
    category :ICategory,
    subCategory : ISubCategory,
    productListFilter : any,
    message:string
    
}

@State<IProductModel>({
name:"Product",
defaults:{
    quality : null,
    category : null,
    subCategory: null,
    productListFilter: null,
    message:''
}
})
@Injectable()
export class ProductState  {
@Selector()
public static getQuality(state:IProductModel): IQuality {
    return state.quality
}

@Selector()
public static getCategory(state:IProductModel): ICategory{
    return state.category
}

@Selector()
public static getSubCategory(state:IProductModel): ISubCategory{
    return state.subCategory
}

@Selector()
public static getProductFiltered(state:IProductModel): any{
    return state.productListFilter
}

@Selector()
public static showMessage(state:IProductModel): any{
    return state.message
}

@Action(GetQuality)
getQuality(
    { getState, setState }: StateContext<IProductModel>,
    { payload }: GetQuality
): void {
    const state = getState();
    setState({
        ...state,
        quality: payload
    })
}

@Action(GetCategory)
getCategory(
    { getState, setState }: StateContext<IProductModel>,
    { payload }: GetCategory
): void {
    const state = getState();
    setState({
        ...state,
        category: payload
    })
}

@Action(GetSubCategory)
getSubCategory(
    { getState, setState }: StateContext<IProductModel>,
    { payload }: GetSubCategory
): void {
    const state = getState();
    setState({
        ...state,
        subCategory: payload
    })
}

@Action(GetProductFiltered)
getProductFiltered(
    { getState, setState }: StateContext<IProductModel>,
    { payload }: GetProductFiltered
): void {
    const state = getState();
    setState({
        ...state,
        productListFilter: payload
    })
}

@Action(ShowMessage)
showMessage(
    { getState, setState }: StateContext<IProductModel>,
    { payload }: ShowMessage
): void {
    const state = getState();
    setState({
        ...state,
        message: payload
    })
}

// @Action(ClickQuality)
// clickQuality(
//     { getState, setState }: StateContext<IProductModel>,
//     { payload }: ClickQuality
// ): void {
//     const state = getState();
//     setState({
//         ...state,
//         clickQuality : payload
//     })
// }


}