import { Role } from "./role";

export class User {
  id?: number;
  // email: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  role?: Role;
  email?: string;
  idUsuario?: number;
  imagenPerfil?: null;
  perfil?: string;
  username?: string;
  token?: string;
}
